import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import {
  fonts,
  heavyFontWeight,
  lightFontWeight,
} from '@rsa-digital/evo-shared-components/theme/JohnLewis/fonts';
import styled, { css } from 'styled-components';

export const StyledUnorderedList = styled.ul`
  text-align: left;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const ListItemWithMargins = styled.li<{ $align?: string }>`
  display: flex;
  justify-content: ${(props) => props.$align};
  flex-direction: column;
  border-bottom: 1px solid ${colors.neutral03};
  flex-wrap: wrap;

  padding: ${spacing(2)} 0;

  ${mediaQuery.tabletPortrait`
    flex-direction: row;
  `}

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(3)} 0;
  `}
`;

const labelAndDetailStyling = css`
  ${fonts.paragraph};
  & {
    font-weight: ${lightFontWeight};
  }
  width: 100%;

  ${mediaQuery.tabletPortrait`
    width: auto;
  `}

  ${mediaQuery.tabletLandscape`
    ${fonts.paragraphLarge};
    & {
      font-weight: ${lightFontWeight};
    }
  `}
`;

export const DetailLabel = styled.span`
  ${labelAndDetailStyling}
`;

export const ValueLabel = styled.span`
  ${labelAndDetailStyling}
  & {
    font-weight: ${heavyFontWeight};
  }

  ${mediaQuery.tabletPortrait`
    margin-left: ${spacing(1)}
  `}

  ${mediaQuery.tabletLandscape`
    & {
      font-weight: ${heavyFontWeight};
    }
  `}
`;
