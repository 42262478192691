import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import { graphql } from 'gatsby';
import React, { Dispatch, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import DiscountPanel from 'components/DiscountPanel';
import FormFooter from 'components/FormFooter';
import Layout from 'components/Layout';
import { Link } from 'components/PolicyDocuments';
import PolicyLink from 'components/PolicyDocuments/PolicyLink';
import UnorderedListWithoutBullets from 'components/UnorderedListWithoutBullets';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { isValidOfferPanel, useValidConfirmationOffers } from 'helpers/discountHelpers';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { handleAndTrackLinkClick } from 'helpers/navigation';
import { usePageTracking } from 'helpers/pageTracking';
import { confirmationQuotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import useConfirmationDetails from 'pages/pet/confirmation/useConfirmationDetails';
import { ERROR, ErrorAction, ErrorType } from 'state/error/actions';
import { ProductType } from 'state/formData/quoteOptions';
import { useConfirmationQuote } from 'state/quote/confirmationQuote';
import useReferenceData from 'state/referenceData/useReferenceData';
import { CsCashbackPanelVariant, CsHero, CsIcon, CsOffer } from 'types/contentStack';
import {
  ConfirmationOfferPanel,
  ConfirmationQuoteDetailsContainer,
  DocumentsDispatchingDetails,
  QuoteReferenceLabel,
  QuoteReferenceValue,
  QuoteReferenceWrapper,
  StyledGridItem,
  ThankYouMessage,
} from './styles';

type ConfirmationProps = {
  data: {
    csPetConfirmationV2: {
      meta_title: string;
      hero: CsHero;
      thank_you_message: string;
      quote_reference_label: string;
      next_button_text: string;
      next_button_url: string;
      documents_dispatching_details: string;
      policy_wording_link: Link;
      bullet_icon: [CsIcon] | [];
    };
    csPetOfferPanel: {
      panel_icon: [CsIcon];
      panel_content_variations: CsCashbackPanelVariant[];
    };
  };
};

export const query = graphql`
  query {
    csPetConfirmationV2 {
      meta_title
      hero {
        heading
        subheading
      }
      thank_you_message
      quote_reference_label
      next_button_text
      next_button_url
      documents_dispatching_details
      policy_wording_link {
        url
        text
        screenreader_text
        open_in_new_tab
      }
      bullet_icon {
        icon_code
      }
    }
    csPetOfferPanel {
      panel_icon {
        icon_code
      }
      panel_content_variations {
        active_routes
        active_cover_levels
        heading
        panel_text
        confirmation_text
        offer_start_date
        offer_end_date
      }
    }
  }
`;

const Confirmation: React.FC<ConfirmationProps> = ({
  data: {
    csPetConfirmationV2: {
      hero,
      thank_you_message,
      quote_reference_label,
      next_button_text,
      next_button_url,
      meta_title,
      documents_dispatching_details,
      policy_wording_link,
      bullet_icon,
    },
    csPetOfferPanel: {
      panel_icon: csOfferPanelIcon,
      panel_content_variations: offerPanelVariations,
    },
  },
}) => {
  const [quote] = useConfirmationQuote();
  const isAnnualPayment = quote?.quoteOptions?.isAnnualPayment;
  const confirmationDetails = useConfirmationDetails(quote, isAnnualPayment);
  const confirmationOffers = useValidConfirmationOffers();

  const catBreedsRefData = useReferenceData('catBreeds');
  const dogBreedsRefData = useReferenceData('dogBreeds');
  const referenceDataLoaded = !!catBreedsRefData && !!dogBreedsRefData;

  const substituteQuotePlacholders = replacePlaceholdersRichText(
    confirmationQuotePlaceholders,
    quote
  );

  usePageTracking(meta_title, !!quote && referenceDataLoaded, quote);

  const dispatchError: Dispatch<ErrorAction> = useDispatch();
  const [errorDispatched, setErrorDispatched] = useReducer(() => true, false);

  if (!quote) {
    if (!errorDispatched) {
      dispatchError({
        type: ERROR,
        errorType: ErrorType.CONFIRMATION_SESSION_EXPIRED,
      });
      setErrorDispatched();
    }

    return null;
  }

  const icon = unwrapSingleton(bullet_icon)?.icon_code;
  const offerPanelIcon = unwrapSingleton(csOfferPanelIcon);

  const validOfferPanelVariant = offerPanelVariations.find(
    ({ active_routes, active_cover_levels, offer_start_date, offer_end_date }) =>
      isValidOfferPanel(
        quote.productId,
        active_routes,
        quote.quoteOptions.productType as ProductType,
        active_cover_levels,
        offer_start_date,
        offer_end_date
      )
  );

  return (
    <Layout
      heading={hero.heading}
      subheading={hero.subheading}
      metaTitle={meta_title}
      pageTitle={PageTitle.Confirmation}>
      <Grid>
        <StyledGridItem desktop={8} tabletLandscape={8}>
          <ThankYouMessage>{thank_you_message}</ThankYouMessage>
          <QuoteReferenceWrapper>
            <QuoteReferenceLabel>{quote_reference_label}</QuoteReferenceLabel>
            <QuoteReferenceValue>{quote.policyInfo?.quoteNumber}</QuoteReferenceValue>
          </QuoteReferenceWrapper>
          <ConfirmationQuoteDetailsContainer data-cy="quote_details">
            <UnorderedListWithoutBullets details={confirmationDetails} />
          </ConfirmationQuoteDetailsContainer>
          <DocumentsDispatchingDetails
            html={substituteQuotePlacholders(documents_dispatching_details)}
            pageTitle={PageTitle.Confirmation}
          />
          <PolicyLink
            icon={icon}
            url={policy_wording_link.url}
            text={policy_wording_link.text}
            screenreaderText={policy_wording_link.screenreader_text ?? undefined}
            openInNewTab={policy_wording_link.open_in_new_tab}
            onClick={() =>
              trackTextButtonClick(
                PageTitle.Confirmation,
                'Policy wording document button'
              )
            }
          />
          {confirmationOffers &&
            confirmationOffers.map((offer: CsOffer) => (
              <DiscountPanel offerPanel={offer} pageTitle={PageTitle.QuoteSummary} />
            ))}
          {validOfferPanelVariant && (
            <ConfirmationOfferPanel
              data-cy="cashback_panel"
              pageTitle={PageTitle.QuoteSummary}
              information={validOfferPanelVariant.confirmation_text}
              icon={offerPanelIcon?.icon_code}
              iconColor={colors.core02}
            />
          )}
        </StyledGridItem>
      </Grid>
      <FormFooter
        contentColumns={{ desktop: 8 }}
        moveNextButton={{
          text: next_button_text,
          href: next_button_url,
          onClick: (e) => {
            handleAndTrackLinkClick(
              next_button_url,
              PageTitle.Confirmation,
              'Go to homepage',
              e
            );
          },
        }}
        pageTitle={PageTitle.Confirmation}
      />
    </Layout>
  );
};

export default Confirmation;
