import React from 'react';
import RichTextWithModal from 'components/RichTextWithModal';
import { processImageAsset } from 'helpers/csTypeProcessors';
import { PageTitle } from 'helpers/eventTracking';
import { CsOffer } from 'types/contentStack';
import { DiscountPanelContainer, SizedImage } from './styles';

const DiscountPanel: React.FC<{
  offerPanel: CsOffer;
  pageTitle: PageTitle;
}> = ({ offerPanel, pageTitle }) => {
  const offerIcon = offerPanel ? processImageAsset(offerPanel.icon) : undefined;

  return (
    <DiscountPanelContainer data-cy="discountPanel">
      {offerIcon && <SizedImage image={offerIcon} />}
      <RichTextWithModal html={offerPanel.content_rich_text} pageTitle={pageTitle} />
    </DiscountPanelContainer>
  );
};

export default DiscountPanel;
